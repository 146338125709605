<template>
    <div>
        {{ $t('actions.logout') }}
    </div>
</template>

<script>
export default {
    name: 'LogoutView',
    async mounted() {
        await this.$store.dispatch('auth/logout', this.$route.params)
    },
}
</script>